<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Research lines
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create research lines')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'researcher.research-lines.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="lines"
              :sort-mapping="sortMapping"
              :title="'research lines'"
              :total="totalLines"
              :fetch-path="'lines/fetchLines'"
              :filter-path="'lines/filter'"
              :export-path="'lines/export'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View research line
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('update research lines')"
                        :href="$router.resolve({name: 'researcher.research-lines.edit', params: { id: field.id }, query: { actAs: $route.query.actAs }}).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete research lines')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view research lines')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Title</label>
                  <input
                    v-model="filters.title"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Title: ${$event.target.value}`, value: `${$event.target.value}` }, 'title')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Area</label>
                  <v-select
                    v-if="areas.length !== undefined"
                    v-model="filters.area"
                    label="name"
                    :options="areas"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All areas
                    </option>
                  </v-select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Publish in web</label>
                  <select
                    v-model="filters.publish_web"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Publish in web: ' + ($event.target.value === 'Publish in web' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'publish_web')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="Publish in web"
                    >
                      Yes
                    </option>
                    <option
                      value="Don't publish in web"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Images</label>
                  <select
                    v-model="filters.images"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Images: ' + ($event.target.value ? 'Yes' : ($event.target.value === null ? 'All' : 'No')), value: $event.target.value }, 'has_images')"
                  >
                    <option
                      :value="null"
                      selected
                    >
                      All
                    </option>
                    <option
                      :value="true"
                    >
                      Yes
                    </option>
                    <option
                      :value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Files</label>
                  <select
                    v-model="filters.Files"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Active: ' + ($event.target.value ? 'Yes' : ($event.target.value === null ? 'All' : 'No')), value: $event.target.value }, 'has_files')"
                  >
                    <option
                      :value="null"
                      selected
                    >
                      All
                    </option>
                    <option
                      :value="true"
                    >
                      Yes
                    </option>
                    <option
                      :value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1">
                    <p>Researcher<br>
                      <strong>{{ item.user.name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Order<br>
                      <strong>{{ item.order }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Title<br>
                      <strong>{{ item.title }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Area<br>
                      <strong>{{ item.area ? item.area.name : '-' }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Description</p>
                    <span v-html="item.body" />
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Publish in web<br>
                      <strong
                        class="badge"
                        :class="item.publish_web ? 'badge-light-success' : 'badge-light-danger'"
                      >{{ item.publish_web ? 'Yes' : 'No' }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Images</p>
                    <div class="row row__image__list">
                      <div
                        v-for="image in item.images"
                        :key="image.id"
                        class="col-sm-6"
                      >
                        <img
                          :src="image.url"
                          class="img-fluid"
                        >
                        <!-- <div
                          class="image__list"
                          :style="{'background': `url('${image.url}') center center no-repeat`}"
                          style="background-size: cover;"
                        /> -->
                      </div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <p>Files</p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>File name</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="file in item.files"
                          :key="file.id"
                        >
                          <td>{{ file.name }}</td>
                          <td class="text-end">
                            <a
                              :href="file.url"
                              target="_blank"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="download file"
                            ><i data-feather="file" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  v-if="hasPermission('update research lines')"
                  class="offcanvas-footer mt-auto"
                >
                  <a
                    title="edit this research line"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="$store.dispatch('modals/toggleInfoItemSheet', false), $router.push({name: 'researcher.research-lines.edit', params: { id: item.id }})"
                  >Edit</a>
                </div>
              </template>
            </CompleteTable>
          </div>
          <div class="col-12">
            <ResearchLinesInformation />
          </div>
        </div>
      </div>
    </div>
    <TableColumns />
  </div>
<!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import ResearchLinesInformation from './components/ResearchLinesInformation.vue'

export default {
  components: {
    TableColumns,
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    ResearchLinesInformation,
  },
  data() {
    return {
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'Order',
          checked: true,
          order: 1,
        },
        {
          name: 'Researcher',
          checked: true,
          order: 2,
        },
        {
          name: 'Title',
          checked: true,
          order: 3,
        },
        {
          name: 'Area',
          checked: true,
          order: 4,
        },
        {
          name: 'Publish in web',
          checked: true,
          order: 5,
        },
        {
          name: 'Images',
          checked: true,
          order: 6,
        },
        {
          name: 'Files',
          checked: true,
          order: 7,
        },
      ],
      sortMapping: {
        Order: 'order',
        Researcher: 'user.name',
        Title: 'title',
        Area: 'area.name',
        'Publish in web': 'publish_web',
        Images: 'images.length',
        Files: 'files.length',
      },
    }
  },
  computed: {
    ...mapGetters({
      lines: 'lines/lines',
      totalLines: 'lines/totalLines',
      fields: 'modals/tableSettingsFields',
      areas: 'areas/areas',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('lines/filter', this.filters)
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    await this.$store.dispatch('modals/fetchUserFields', 'lines')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'lines',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    openTableSettings() {
      this.$store.dispatch('modals/toggleTableSettings', true)
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'lines/fetchLines', url: `${Vue.prototype.$groupUrl}/research-lines/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
