<template>
  <!-- BEGIN: Content-->
  <div class="card">
    <div
      v-b-toggle="`collapsLinesInformation`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Research lines unfolded</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapsLinesInformation`"
    >
      <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body">
          <div
            v-for="line in lines"
            :key="line.id"
            class="card"
          >
            <h3>{{ line.order }}. {{ line.title }}</h3>
            <p v-html="line.body" />
            <p><strong>Area: </strong> {{ line.area ? line.area.name : '-' }}</p>
            <div
              v-if="line.files && line.files.length > 0"
              class="row mb-3"
            >
              <template
                v-for="file in line.files"
              >
                <a
                  :key="file.id"
                  class="col-2"
                  :href="file.url"
                  target="_blank"
                >
                  <i data-feather="file-text" /> {{ file.name }}
                </a>
              </template>
            </div>
            <div
              v-if="line.images && line.images.length > 0"
              class="card no-shadow"
            >
              <div class="card-body">
                <div class="row">
                  <div
                    v-for="image, index in line.images"
                    :key="index"
                    class="col-md-4"
                  >
                    <img
                      :key="image.id"
                      class="img-fluid col-3"
                      :class="index != 0 ? 'pl-1' : ''"
                      :src="image.url"
                    >
                    <p
                      v-if="image.description"
                      :key="image.id"
                      class="fst-italic"
                    >{{ image.description }}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  computed: {
    ...mapGetters({
      lines: 'lines/lines',
    }),
  },
}
</script>
